import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { WebsocketService } from './websocket.services';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LogService {
  public messages: Subject<string>;
  private url: string;

  constructor(
    private wsService: WebsocketService
  ) {
    this.url = window["configuration"].webSocketPath.toString() + "/ws?socketId=" + localStorage.getItem('username');
  }

  public GetMessages() {
    return <Subject<string>>this.wsService.connect(this.url).pipe(
      map(
        (response: MessageEvent): string => {
          return JSON.parse(response.data);
        }
      )
    );
  }

  public close(): void {
    this.wsService.close();
  }
}
