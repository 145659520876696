import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouteReuseStrategy } from '@angular/router';
import { AppConfigService } from '../../core/services/config.service';
import { AppTranslationLoaderService } from 'src/app/core/services/translation-loader.service';
import { AppNavigationModel } from '../../navigation/navigation.model';
import { NavigationService } from '../../core/components/navigation/navigation.service';
import { LogService } from '../content/sections/websocketclient/notification.services';
import { NotificationService } from '../../core/services/notification/notification.service';
import { ApplicationInfoService } from '../../core/translate/application-info.service';
import { TransactionResult } from '../../core/services/interfaces/transaction-result';
import { AppsInfo } from '../../core/translate/models/app-info';
import { environment } from '../../../environments/environment';
import { TranslateApplicationService } from '../../core/translate/translate-application-service';
import { Application } from '../../core/translate/models/application';
import { ISites } from '../../main/content/sections/Interfaces/ISites';
import { SitesService } from '../../main/content/sections/services/sites-services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CognitoService } from '../../core/services/cognito/cognito.service';
declare var jQuery: any;

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class AppToolbarComponent {
  userStatusOptions: any[];
  languages: Array<any> = [];
  selectedLanguage: any;
  showLoadingBar: boolean;
  horizontalNav: boolean;
  username: string;
  listSites: Array<ISites> = [];
  formGroup: FormGroup;

  constructor(
    private router: Router,
    private appConfig: AppConfigService,
    private translate: TranslateApplicationService,
    private siteService: SitesService,
    private cognito: CognitoService,
    private formBuilder: FormBuilder,
    private appNavigationService: NavigationService,
    private applications: ApplicationInfoService,
    private logService: LogService
  ) {

    this.formGroup = this.formBuilder.group({
      fksite: [null, Validators.required],
    });

    this.applications.getApplicationByName().subscribe(
      (result: Application) => {
        result.languages.forEach(p => {
          var language = {
            id: p.id,
            title: p.language,
            flag: p.code,
            applicationId: result.id
          };
          this.languages.push(language);
        });

        this.selectLanguage();
      },
      (error) => {
        console.log(error);
      }
    );

    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.showLoadingBar = true;
        }
        if (event instanceof NavigationEnd) {
          this.showLoadingBar = false;
        }
      });

    this.appConfig.onSettingsChanged.subscribe((settings) => {
      this.horizontalNav = settings.layout.navigation === 'top';
    });

    if (!window.location.href.includes('public')) {
      if (this.cognito.IsAuhtenticated()) {
        this.cognito.getUserInfo().subscribe(result => {
          this.username = result['email'];
          let isDev = window["configuration"].isDev;
          let userNT = "";
          if (isDev) {
            //DEV
            userNT = result['cognito:username'];
            userNT = userNT.slice(5);
          }
          else {
            // STG | PRD
            userNT = result['custom:samaccountname'];
          }

          localStorage.setItem('username', userNT);

          var email = result['email'];
          localStorage.setItem('email', email);

          this.getSites();
          this.logService.GetMessages().subscribe(msg => {
            console.log(msg);
          });
        });
      }
    }
    else {
      setTimeout(function () {
        jQuery(".divSites").hide();
        jQuery(".divLabelSites").hide();
      }, 500);
    }
  }

  getSites() {
    let first = true;
    this.siteService.getSitesByUsername().subscribe(
      (result: TransactionResult<ISites[]>) => {
        if (result.data.length > 0) {
          result.data.forEach(obj => {
            if (obj.active == true) {
              if (first) {
                localStorage.setItem('fksite', obj.fksite.toString());
                this.formGroup.controls.fksite.setValue(obj.fksite);
              }
              first = false;
              this.listSites.push(obj);
            }
          })
          /*La siguiente linea crea todo el menu */
          this.appNavigationService.setNavigationModel(new AppNavigationModel());
        }
        else
          this.router.navigateByUrl("/main/without-system-permission");
      },
      (error: any) => { console.log(error); }
    );
  }

  changeSite() {
    if (this.cognito.IsAuhtenticated()) {
      localStorage.setItem('fksite', this.formGroup.controls.fksite.value);
      jQuery(".btnReload").trigger('click');
      /*La siguiente linea crea todo el menu */
      this.appNavigationService.setNavigationModel(new AppNavigationModel());
    }
  }

  selectLanguage(): void {
    let indexLang = 0;
    if (this.languages != undefined) {
      if (navigator.language.split('-').length > 0) {
        indexLang = this.languages.findIndex(lang => lang.flag === navigator.language.split('-')[0]);
      }
      else {
        indexLang = this.languages.findIndex(lang => lang.flag === navigator.language);
      }

      if (indexLang < 0) {
        this.selectedLanguage = this.languages[0];
      } else {
        this.selectedLanguage = this.languages[indexLang];
      }
    }
  }

  setLanguage(lang) {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;
    // selected language for translations
    this.translate.use(lang);
  }

  logOut(): void {
    this.logService.close();
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('fksite');
    this.cognito.logOut();
  }
}
